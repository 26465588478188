<template>
  <div class="d-flex w-100">
    <TInputSimpleSearch
      placeholder="Search invoice"
      :value.sync="search"
      @update:value="triggerSearch"
      class="w-100 mr-1"
    />
    <SButtonFilter
      :active="!lodash.isEmpty(query)"
      :badge="lodash.size(query)"
      class="ml-auto my-auto"
      @click="show = true"
    />
    <TModal title="Filter" :show="show" @update:show="show = $event">
      <template #actions>
        <SButtonClearFilter
          @click="clearFilter"
          :disabled="loading || lodash.isEmpty(allQuery)"
        />
        <SButtonSearchFilter :disabled="loading" @click="onFilter" />
      </template>

      <CRow>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectShipmentMethod
            label="Shipment method"
            :value.sync="filter.shipment_method_id"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputDateTimeRange
            label="Arrival date"
            :value.sync="dateRange"
            @update:value="setDateRangeFilter"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectFreightForwarder
            label="Freight forwarder"
            :value.sync="filter.freight_forwarder_id"
            :prepend="[{ id: '', name: $t('All') }]"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TSelect
            label="Status"
            class="m-0"
            placeholder="Select"
            :options="status"
            :value="valueSelected"
            @select="onSelect"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectStatus
            :value.sync="formSelected"
            label="Form"
            :options="[
              {
                id: '',
                name: $t('All'),
              },
              {
                id: '1',
                name: $t('White'),
              },
              {
                id: '0',
                name: $t('Black'),
              },
            ]"
            @change="setFormContainerFilter"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
import filter from "../mixins/filter";
export default {
  mixins: [filter],
  data() {
    return {
      blockParams: ["tab", "action", "invoice_id", "select_lading_bill_id"],
      searchFilter: "name",
      store: "warehouse.invoices",
      betweenDates: "arrival_date",
      status: [
        { id: "", name: this.$t("All") },
        { id: "1", name: this.$t("Closed") },
        { id: "0", name: this.$t("Open") },
      ],
      valueSelected: null,
      formSelected: "",
    };
  },
  watch: {
    "filter.had_cut_off": {
      immediate: true,
      handler(value) {
        this.valueSelected = this.status.find((x) => x.id == value) || {
          id: "",
          name: this.$t("All"),
        };
      },
    },
    "filter.property_white": {
      immediate: true,
      handler(val) {
        this.formSelected = val || "";
      },
    },
  },
  methods: {
    onSelect(value) {
      this.valueSelected = value;
      this.filter["had_cut_off"] = value.id;
    },
    setFormContainerFilter(value) {
      if (+value.id) this.filter.property_white = value.id;
      else this.filter.property_white = value.id;
    },
  },
};
</script>
