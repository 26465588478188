<template>
  <TModal
    title="Add customer"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="customer_id ? false : true"
  >
    <template #actions>
      <TButton
        content="Add"
        :options="{ disabled: loading || !customer_id }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <SSelectCustomer
      label="Customer"
      :value.sync="customer_id"
      @update:value="customer_id = $event"
      noFetchStore
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customer_id: "",
      loading: false,
    };
  },
  methods: {
    add() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.invoices.detail.attach-customer", {
          "customer_ids[]": this.customer_id,
        })
        .then(() => {
          this.$emit("updated");
        })
        .finally(() => {
          this.customer_id = "";
          this.loading = false;
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
