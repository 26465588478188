<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="HAWB" bold noTranslate />
      <div class="ml-auto">
        <TButton
          content="Add HAWB"
          variant="outline"
          class="mr-1"
          icon="cil-plus"
          @click="showModalAddHawb = true"
        />
      </div>
      <AddHAWB
        v-if="invoice.id"
        :show.sync="showModalAddHawb"
        :invoiceId="invoice.id"
        @updated="$emit('updated')"
      />
    </CCardHeader>
    <CCardBody>
      <TTableSimple
        v-if="invoice.house_airway_bills.length"
        :items="invoice.house_airway_bills"
        :fields="hawbFields"
        noResult
      >
        <template #_="{ item }">
          <td>
            <div class="d-flex">
              <TButtonEdit @click="selectHawb(item)" />
              <TButtonDelete @click="deleleHawb(item.id)" />
            </div>
          </td>
        </template>
        <template #flight_code="{ item }">
          <td>
            <TMessage :content="item.flight_code" noTranslate />
          </td>
        </template>
        <template #max_volume="{ item }">
          <td>
            <TMessageVolume :value="item.max_volume" />
          </td>
        </template>
        <template #max_weight="{ item }">
          <td>
            <TMessageNumber :value="item.max_weight" suffix="kg" />
          </td>
        </template>
        <template #created_at="{ item }">
          <td>
            <TMessageDateTime :content="item.created_at" dateOnly />
          </td>
        </template>
        <template #voucher="{ item }">
          <td>
            <div class="d-flex">
              <TButton
                v-if="
                  item.info_attachment && checkMimeType(item.info_attachment)
                "
                icon="cil-eye"
                size="sm"
                class="mr-1"
                variant="outline"
                @click="selectVoucher(item.path_attachment)"
              />
              <CLink :href="getUrlAttachment(item.path_attachment)">
                <TButton
                  v-if="item.path_attachment"
                  color="info"
                  size="sm"
                  variant="outline"
                  icon="cid-cloud-download"
                />
              </CLink>
            </div>
          </td>
        </template>
      </TTableSimple>
      <TMessageNoData v-else />
      <TModal
        size=""
        title="Voucher"
        :show.sync="showVoucherReview"
        v-if="showVoucherReview"
      >
        <template #actions>
          <span></span>
        </template>
        <CImg
          style="object-fit: contain"
          thumbnail
          fluidGrow
          :src="getUrlAttachment(path_file)"
          placeholderColor
          class="p-0 d-flex mx-auto"
        />
      </TModal>
      <EditHAWB
        :detail="hawb_selected"
        :invoiceId="invoice.id"
        :show.sync="showModalEditHawb"
        editable
        @updated="$emit('updated')"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import AddHAWB from "./HAWBFormModal.vue";
import EditHAWB from "./HAWBFormModal.vue";
export default {
  components: { AddHAWB, EditHAWB },
  props: {
    invoice: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showModalAddHawb: false,
      showModalEditHawb: false,
      showVoucherReview: false,
      hawbFields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Id",
          _style: "min-width: 100px",
          _classes: "text-wrap",
        },
        { key: "flight_code", label: "Flight code" },
        {
          key: "max_volume",
          label: "Volumetric weight",
        },
        {
          key: "max_weight",
          label: "Actual weight",
        },
        {
          key: "created_at",
          label: "Created at",
        },
        {
          key: "voucher",
          label: "Voucher",
        },
      ],
      path_file: "",
      hawb_selected: {},
    };
  },
  methods: {
    getUrlAttachment(path_file) {
      return process.env.VUE_APP_WAREHOUSE_SERVICE_HOST + "/files/" + path_file;
    },
    selectVoucher(v) {
      this.path_file = v;
      this.showVoucherReview = true;
    },
    checkMimeType(type) {
      return ["image/png", "image/jpeg", "image/webp", "image/tiff"].includes(
        type?.mime_type
      );
    },
    selectHawb(item) {
      this.hawb_selected = item;
      this.showModalEditHawb = true;
    },
    deleleHawb(id) {
      this.$store
        .dispatch("warehouse.house_airway_bills.delete", id)
        .then(() => {
          this.$emit("updated");
        });
    },
  },
};
</script>
