<template>
  <div>
    <CBadge color="info" style="font-size: 85%" class="px-2">
      <TMessage
        :content="
          customer.customer ? customer.customer.name : customer.customer_id
        "
        :removable="removable"
        @click-remove="showModalConfirm = true"
        bold
        noTranslate
      >
      </TMessage>
    </CBadge>
    <TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="detachCustomer"
    />
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  methods: {
    detachCustomer() {
      this.$store
        .dispatch("warehouse.invoices.detail.detach-customer", {
          customer_ids: [this.customer.customer_id],
        })
        .then(() => {
          this.$emit("deleted");
        });
    },
  },
};
</script>
