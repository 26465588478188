<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <TMessage content="Invoice" bold>
            <template #suffix>
              <span class="ml-1">{{ invoice.name }}</span>
            </template>
          </TMessage>
          <SMessageContainerStatus
            v-if="invoice"
            class="ml-1 my-auto"
            :status="invoice.had_cut_off"
          />
          <TButtonEnter
            class="ml-2"
            v-if="is_allow_redirect_to_invoice_page"
            @click="redirectToInvoicePage"
          />
          <TSpinner :loading="detail_loading" />
        </div>
        <div class="ml-auto d-flex">
          <CDropdown color="info" class="mr-2">
            <template #toggler-content>
              <CSpinner v-if="exportInvoiceLoading" color="white" size="sm" class="mx-1" />
              <CIcon v-else name="cid-cloud-download" class="mr-2 m-0" />
              <span>{{ $t("Export invoice") }}</span>
            </template>
            <CDropdownItem @click.native="exportInvoice('expected')"
              ><TMessage content="Expected"
            /></CDropdownItem>
            <CDropdownItem @click.native="exportInvoice('actual-in-container')"
              ><TMessage content="Actual in container"
            /></CDropdownItem>
            <CDropdownItem @click.native="exportInvoice('actual')"
              ><TMessage content="Actual"
            /></CDropdownItem>
          </CDropdown>
          <CDropdown color="primary" class="mr-2">
            <template #toggler-content>
              <CSpinner v-if="exportDistributionLoading" color="white" size="sm" class="mx-1" />
              <CIcon v-else name="cid-cloud-download" class="mr-2 m-0" />
              <span>{{ $t("Export of goods") }}</span>
            </template>
            <CDropdownItem @click.native="exportDistribution('expected')"
              ><TMessage content="Expected"
            /></CDropdownItem>
            <CDropdownItem @click.native="exportDistribution('actual')"
              ><TMessage content="Actual"
            /></CDropdownItem>
          </CDropdown>
          <TButton
            v-if="invoice.id"
            content="Add customer"
            variant="outline"
            class="mr-1"
            icon="cil-plus"
            @click="showModalAddCustomer = true"
          />
          <TButtonDelete
            v-if="invoice.from_area_id == warehouseScope"
            @click="removeInvoice"
            class="mr-1 my-auto"
            variant=""
            :options="{ disabled: detail_loading }"
          />
          <AddCustomerForCont
            v-if="invoice.id"
            :show.sync="showModalAddCustomer"
            @updated="refetchContainer"
          />
          <TButtonRefresh
            @click="refetchContainer"
            class="my-auto"
            :options="{ disabled: detail_loading }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="detailFields"
              :data="invoice"
              :splitLeft="7"
              title="Container"
              class="mb-3"
            >
              <template #name="{ value }">
                <TMessageText
                  :editable="!detail_loading"
                  :value="value"
                  @change="updateContainer('name', $event)"
                />
              </template>
              <template #consignee="{ value }">
                <TMessageText
                  :editable="!detail_loading"
                  :value="value"
                  @change="updateContainer('consignee', $event)"
                />
              </template>
              <template #freight_forwarder_id="{ value }">
                <SMessageFreightForwarder
                  :editable="!detail_loading"
                  :value="value"
                  :content="
                    invoice.freight_forwarder
                      ? invoice.freight_forwarder.name
                      : '...'
                  "
                  @change="updateContainer('freight_forwarder_id', $event)"
                />
              </template>
              <template #type_id="{ value }">
                <TMessage
                  noTranslate
                  :content="invoice.type ? invoice.type.name : value"
                />
              </template>
              <template #note="{ value }">
                <TMessageTextarea
                  :editable="!detail_loading"
                  :value="value"
                  @change="updateContainer('note', $event)"
                />
              </template>
              <template #shipment_method_id="{ value }">
                <SMessageShipmentMethod :id="value" />
              </template>
              <template #property_white="{ value }">
                <TSwitch
                  :checked="value"
                  :disabled="detail_loading"
                  @change="updateContainer('property_white', $event)"
                />
              </template>

              <template #from_area_id="{ data }">
                <TMessage
                  uppercase
                  v-if="data.from_area"
                  :content="data.from_area.name"
                  bold
                  noTranslate
                />
              </template>
              <template #to_area_id="{ data }">
                <TMessage
                  uppercase
                  v-if="data.to_area"
                  :content="data.to_area.name"
                  bold
                  noTranslate
                />
              </template>
              <template #automatic_add_lading_bill="{ value }">
                <TSwitch
                  :checked="value"
                  :disabled="detail_loading"
                  @change="updateContainer('automatic_add_lading_bill', $event)"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              title="Dates"
              :fields="invoiceDateFields"
              :data="invoice"
              :splitLeft="7"
              class="mb-3"
            >
              <template #cut_off_date="{ value }">
                <TMessageDateTime
                  v-show="value"
                  :content="value"
                  dateOnly
                  class="font-weight-bold"
                />
              </template>
              <template #arrival_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('arrival_date', $event)"
                />
              </template>
              <template #vanning_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('vanning_date', $event)"
                />
              </template>
              <template #shipping_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('shipping_date', $event)"
                />
              </template>
              <template #departure_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('departure_date', $event)"
                />
              </template>
              <template #booking_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('booking_date', $event)"
                />
              </template>
              <template #manifest_date="{ value }">
                <TMessageDateTime
                  dateOnly
                  :content="value"
                  :editable="!detail_loading"
                  @change="updateContainer('manifest_date', $event)"
                />
              </template>
            </TTableAsForm>
            <TMessage content="Customer" class="mb-2 mt-3" uppercase>
              <template #suffix>:</template>
            </TMessage>
            <div
              v-if="invoice.customers && invoice.customers.length"
              class="d-flex flex-wrap"
            >
              <MessageContainerCustomer
                v-for="customer in invoice.customers"
                :key="customer.id"
                class="mr-1 mb-1"
                :customer="customer"
                :removable="!detail_loading"
                @deleted="refetchContainer"
              />
            </div>
            <TMessageNotFound v-else />
          </CCol>
        </CRow> </CCardBody
    ></CCard>
    <CCard>
      <CCardHeader>
        <TMessage content="Container parameters" bold />
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="12" xl="6" class="mb-2">
            <TTableAsForm
              :splitLeft="7"
              :fields="parameterFields"
              :data="invoice"
              class="mb-3"
            >
              <template #max_volume="{ value }">
                <TMessageVolume
                  :value="value"
                  class="font-weight-bold"
                  :editable="!detail_loading"
                  suffix=""
                  @change="updateContainer('max_volume', $event)"
                />
              </template>
              <template #max_weight="{ value }">
                <TMessageNumber
                  :value="value"
                  class="font-weight-bold"
                  :editable="!detail_loading"
                  @change="updateContainer('max_weight', $event)"
                />
              </template>
              <template #offset_max_volume="{ value }">
                <TMessageVolume
                  :value="value"
                  suffix=""
                  class="font-weight-bold"
                  :editable="!detail_loading"
                  @change="updateContainer('offset_max_volume', $event)"
                />
              </template>
              <template #offset_max_weight="{ value }">
                <TMessageNumber
                  :value="value"
                  class="font-weight-bold"
                  :editable="!detail_loading"
                  @change="updateContainer('offset_max_weight', $event)"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6" class="mb-3">
            <div class="my-1">
              <TMessage content="Volume" :addClasses="['d-flex flex-wrap']">
                <template #suffix>
                  (m3):
                  <TMessageVolume
                    class="mx-1"
                    :messageOptions="{ bold: true }"
                    :value="invoice.volume"
                    suffix=""
                  />
                  /
                  <TMessageVolume
                    :messageOptions="{ bold: true }"
                    class="mx-1"
                    :value="invoice.total_max_volume"
                    suffix=""
                  />
                </template>
              </TMessage>
              <CProgress
                :value="invoice.volume"
                :max="invoice.total_max_volume"
                height="15px"
              />
            </div>
            <div class="mt-1 mb-2">
              <TMessage content="Weight" :addClasses="['d-flex flex-wrap']">
                <template #suffix>
                  (kg):
                  <TMessageNumber
                    :messageOptions="{ bold: true }"
                    class="mx-1"
                    :value="invoice.weight"
                  />
                  /
                  <TMessageNumber
                    :messageOptions="{ bold: true }"
                    class="mx-1"
                    :value="invoice.total_max_weight"
                  />
                </template>
              </TMessage>
              <CProgress
                :value="invoice.weight"
                :max="invoice.total_max_weight"
                height="15px"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <HAWBInfo
      v-if="lodash.has(invoice, 'id')"
      :invoice="invoice"
      @updated="refetchContainer"
    />
  </div>
</template>

<script>
import AddCustomerForCont from "./AddCustomerForCont.vue";
import MessageContainerCustomer from "./MessageContainerCustomer";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
import { mapGetters } from "vuex";
import HAWBInfo from "./HAWBInfo.vue";
export default {
  components: { AddCustomerForCont, MessageContainerCustomer, HAWBInfo },
  props: {
    invoice: {
      type: Object,
      required: false,
    },
    is_allow_redirect_to_invoice_page: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      invoiceDateFields: [
        { key: "booking_date", label: "Booking date" },
        { key: "cut_off_date", label: "Cut off date" },
        { key: "shipping_date", label: "Shipping date" },
        { key: "departure_date", label: "Departure date" },
        { key: "vanning_date", label: "Date of shipment" },
        { key: "arrival_date", label: "Arrival date" },
        { key: "manifest_date", label: "Manifest date" },
      ],
      detailFields: [
        { key: "name", label: "Container" },
        { key: "type_id", label: "Type" },
        { key: "property_white", label: "White" },
        { key: "freight_forwarder_id", label: "Freight forwarder" },
        { key: "consignee", label: "Consignee" },
        { key: "shipment_method_id", label: "Shipment method" },
        { key: "from_area_id", label: "From" },
        { key: "to_area_id", label: "To" },
        {
          key: "automatic_add_lading_bill",
          label: "Automatic add lading bill",
        },
        {
          key: "note",
          label: "Note",
        },
      ],
      parameterFields: [
        { key: "max_volume", label: "Storage volume (m3)" },
        { key: "max_weight", label: "Storage weight (kg)" },
        { key: "offset_max_volume", label: "Extra volume (m3)" },
        { key: "offset_max_weight", label: "Extra weight (kg)" },
      ],
      showModalAddCustomer: false,
      exportDistributionLoading: false,
      exportInvoiceLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      detail_loading: "warehouse.invoices.detail.loading",
    }),
    warehouseScope() {
      return warehouseScope.get();
    },
  },
  methods: {
    updateContainer(field, value) {
      this.$store.dispatch("warehouse.invoices.detail.update", {
        [field]: value,
      });
    },
    refetchContainer() {
      this.$store.dispatch("warehouse.invoices.detail.refresh");
    },
    exportInvoice(type) {
      this.exportInvoiceLoading = true
      this.$store
        .dispatch("helper.exports.export-invoice", {
          type: type,
          container_id: this.invoice.id,
        })
        .then((data) => {
          this.exportInvoiceLoading = false
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = `${type}-${this.invoice.id}.xlsx`;
          link.click();
        });
    },
    exportDistribution(type) {
      this.exportDistributionLoading = true
      this.$store
        .dispatch("helper.exports.export-distribution", {
          type: type,
          container_id: this.invoice.id,
        })
        .then((data) => {
          this.exportDistributionLoading = false
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = `${type}-product-sorting-${this.invoice.id}.xlsx`;
          link.click();
        });
    },
    redirectToInvoicePage() {
      this.$router.push({
        path: `/warehouse/internal/invoices?invoice_id=${this.invoice.id}`,
      });
    },
    removeInvoice() {
      this.$store.dispatch("warehouse.invoices.detail.delete").then(() => {
        this.$router.push(`/warehouse/internal/invoices`);
      });
    },
  },
};
</script>
