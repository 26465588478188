export default {
  props: {
    queries: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      filter: {},
      filter_between: {},
      betweenDates: "updated_at",
      search: "",
      dateRange: {
        start: null,
        end: null,
      },
      show: false,
    };
  },
  watch: {
    query: {
      immediate: true,
      handler(value) {
        this.filter = this.lodash.omit(this.lodash.clone(value), this.betweenDates);
        this.filter_between = this.lodash.pick(this.lodash.clone(value), this.betweenDates);
        if (value[this.betweenDates]) {
          const range = this.query?.[this.betweenDates]?.split(",", 2);
          const result = {
            start: parseInt(range[0]),
            end: parseInt(range[1]),
          };
          this.dateRange = result
        }
        else this.dateRange = {};
        this.$emit("update:queries", { ...this.getFilterFields() });
      },
    },
    "$route.query.search": {
      immediate: true,
      handler(value) {
        this.search = value
      }
    },
  },
  computed: {
    loading() {
      return this.$store.getters[this.store + ".loading"]
    },
    query() {
      return this.lodash.omit(this.$route.query, ["search", ...this.blockParams]);
    },
    allQuery() {
      return this.lodash.omit(this.$route.query, [...this.blockParams]);
    },
    blockQuery() {
      return this.lodash.pick(this.$route.query, [...this.blockParams]);
    },
    allQuerySelected() {
      return { ...this.filter, ...this.filter_between }
    }
  },
  methods: {
    setDateRangeFilter({ start, end }) {
      this.filter_between[this.betweenDates] = `${start},${end}`;
    },
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: this.lodash.pickBy({
          search: this.$route.query.search,
          ...this.blockQuery,
          ...this.filter,
          ...this.filter_between
        }),
      });
    },
    getFilterFields() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filter_between = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filter_between,
        [`filter[${this.searchFilter}]`]: this.$route.query.search,
      });
      return filterFields;
    },
    onFilter() {
      this.appendRoute();
      this.$store
        .dispatch(this.store + ".apply-query", { ...this.getFilterFields() })
        .finally(() => {
          this.show = false;
        });
    },
    triggerSearch(value) {
      this.lodash.appendRouteQuery(this.$router, this.$route, {
        search: value,
      });
      this.$store.dispatch(this.store + ".apply-query", {
        ...this.getFilterFields(),
        [`filter[${this.searchFilter}]`]: value,
      });
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.$router.push({ query: this.blockQuery }).then(() => {
        this.onFilter();
      });
      this.dateRange = {
        start: null,
        end: null,
      };
    },
  },
}
