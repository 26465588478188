<template>
  <TModal
    :title="editable ? 'Edit' : 'Add HAWB'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
  >
    <template #actions>
      <TButton
        :content="editable ? 'Save' : 'Add'"
        :options="{ disabled: loading }"
        :icon="editable ? 'cil-check' : 'cil-plus'"
        @click="editable ? update() : add()"
      />
    </template>
    <TInputText
      label="Id"
      class="mb-2"
      :value.sync="input.id"
      @update:value="input.id = $event"
    />
    <TInputText
      label="Flight code"
      class="mb-2"
      :value.sync="input.flight_code"
      @update:value="input.flight_code = $event"
    />
    <TInputNumber
      class="mb-2"
      label="Volumetric weight"
      :maskOptions="{ integerLimit: 3 }"
      :value.sync="input.max_volume"
    />
    <TInputNumber
      class="mb-2"
      label="Actual weight"
      :value.sync="input.max_weight"
    />
    <TInputFile
      label="Receipts"
      :key="inputFileKey"
      :value.sync="files"
      @update:value="changeFile"
      :multiple="false"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    invoiceId: {
      type: [String, Number],
      required: true,
      default: "",
    },
    detail: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      input: {},
      inputFileKey: "inputfilekey",
      attachment: {},
      files: [],
    };
  },
  watch: {
    detail: {
      immediate: false,
      handler(obj) {
        if (!this.lodash.isEmpty(obj)) {
          this.input = {
            ...this.lodash.pick(obj, ["id", "flight_code", "max_weight"]),
            max_volume: obj.max_volume / 1000000,
          };
          if (obj.info_attachment)
            this.files = [
              {
                name: obj.path_attachment,
                size: obj.info_attachment?.size,
                type: obj.info_attachment?.mime_type,
                ext: obj.info_attachment?.extension,
                url:
                  process.env.VUE_APP_WAREHOUSE_SERVICE_HOST +
                  "/files/" +
                  obj.path_attachment,
              },
            ];
          else this.files = [];
        }
      },
    },
  },
  methods: {
    add() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.house_airway_bills.create", {
          ...this.lodash.pickBy({
            ...this.input,
            max_volume: this.input.max_volume * 1000000,
            attachment: this.attachment?.file,
          }),
          container_id: this.invoiceId,
        })
        .then(() => {
          this.files = [];
          this.input = {};
          this.attachment = {};
          this.$emit("updated");
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      const data = {
        ...this.input,
        id: this.input.id == this.detail.id ? "" : this.input.id,
        max_volume: this.input.max_volume * 1000000,
      };
      this.$store
        .dispatch("warehouse.house_airway_bills.update", {
          id: this.detail.id,
          attributes: {
            ...this.lodash.pickBy({
              ...data,
              attachment: this.attachment?.file,
              action_remove_attachment:
                !this.files.length && this.detail.info_attachment ? 1 : 0,
            }),
            flight_code: data.flight_code ? data.flight_code : "",
          },
        })
        .then(() => {
          this.$emit("updated");
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeFile(file) {
      if (file.length > 0) this.attachment.file = file[0].file;
      else this.attachment.file = null;
    },
  },
};
</script>
