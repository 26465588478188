<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem :to="`?action=new_invoice`" color="success">
            <TMessage content="New invoice" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <FilterForInvoices :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(invoice, index) in invoices"
            :key="`${index}${invoice.id}`"
            :color="invoice_id_selected == invoice.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { invoice_id: invoice.id })"
            :class="[
              'p-0',
              {
                'list-group-item-success':
                  invoice.had_cut_off && !(invoice_id_selected == invoice.id),
              },
            ]"
          >
            <SCardInvoice :invoice="invoice" />
          </CListGroupItem>
          <CListGroupItem v-if="!invoices.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.invoices" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <TTabs
        v-show="invoice_id_selected"
        :tabs="tabs"
        :activeTab="$route.query.tab"
        @change-tab="changeTab"
      >
        <template #list>
          <CRow v-if="invoice_id_selected">
            <CCol col="12">
              <CCard>
                <CCardHeader class="d-flex align-items-center">
                  <TMessage content="Lading bills in invoice" bold>
                    <template #suffix>
                      <span class="ml-1">{{
                        invoice_selected.name || "..."
                      }}</span>
                    </template>
                  </TMessage>
                  <TSpinner :loading="detail_loading" />
                  <TButton
                    :disabled="invoice_selected.had_cut_off"
                    content="Add lading bill"
                    icon="cil-file-add"
                    class="ml-auto mr-2"
                    variant="outline"
                    @click="showModalSelectLadingBill = true"
                  />
                </CCardHeader>
                <CCardBody>
                  <TMessageNoData
                    content="No lading bills"
                    v-if="!lading_bills.length"
                  />
                  <SCardLadingBill
                    v-for="lading_bill in lading_bills"
                    :key="lading_bill.id"
                    :lading_bill="lading_bill"
                    removable
                    @remove="removeLadingBill"
                    @updated="fetchLadingBills"
                  />
                  <SButtonLoadmore store="warehouse.lading_bills" />
                </CCardBody>
              </CCard>
            </CCol>
            <SModalSelectLadingBill
              :show.sync="showModalSelectLadingBill"
              @select="addLadingBill"
              store="warehouse.lading_bills_available_for_invoice"
              :default_filter="{
                'filter[shipment_method_id]':
                  invoice_selected.shipment_method_id,
              }"
            />
          </CRow>
        </template>
        <template #detail>
          <InvoiceDetail :invoice="invoice_selected" />
        </template>
        <template #list-badge>
          <CBadge color="primary" v-if="invoice_selected.lading_bills">{{
            invoice_selected.lading_bills.length
          }}</CBadge>
        </template>
      </TTabs>
      <CCard v-show="!invoice_id_selected">
        <CCardHeader>
          <TMessage content="Create new invoice" bold />
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="7">
              <SSelectContainerType
                label="Container type"
                class="mb-2"
                :value.sync="invoice_input.type_id"
                @change="selectContainerType"
                :prepend="[{ id: '', name: $t('Select') }]"
              />
              <TInputText
                class="mb-2"
                label="Name"
                :value.sync="invoice_input.name"
              />
              <SSelectArea
                label="Move to"
                class="mb-2"
                :prepend="[]"
                :value.sync="invoice_input.to_area_id"
              />
              <SSelectShipmentMethodBox
                :key="shipment_method_key"
                class="mb-2"
                :value.sync="invoice_input.shipment_method_id"
              />
            </CCol>
            <CCol lg="5">
              <CRow>
                <CCol lg="12">
                  <TInputDateTime
                    class="mb-2"
                    dateOnly
                    label="Booking date"
                    :value.sync="invoice_input.booking_date"
                  />
                  <TInputDateTime
                    class="mb-2"
                    dateOnly
                    label="Shipping date"
                    :value.sync="invoice_input.shipping_date"
                  />
                  <TInputDateTime
                    class="mb-2"
                    dateOnly
                    label="Arrival date"
                    :value.sync="invoice_input.arrival_date"
                  />
                </CCol>
                <CCol lg="12">
                  <TInputNumber
                    class="mb-2"
                    label="Storage volume (m3)"
                    :maskOptions="{ integerLimit: 3 }"
                    :value.sync="invoice_input.max_volume"
                    :inputOptions="{ disabled: !!invoice_input.type_id }"
                  />
                  <TInputNumber
                    class="mb-2"
                    label="Storage weight (kg)"
                    :value.sync="invoice_input.max_weight"
                    :inputOptions="{ disabled: !!invoice_input.type_id }"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol col="12">
              <hr />
            </CCol>
            <CCol col="7">
              <SSelectFreightForwarder
                class="mb-2"
                label="Freight forwarder"
                :value.sync="invoice_input.freight_forwarder_id"
              />
              <TInputText
                class="mb-2"
                label="Consignee"
                :value.sync="invoice_input.consignee"
              />
              <TInputText
                class="mb-2"
                label="Note"
                :value.sync="invoice_input.note"
              />
            </CCol>
            <CCol col="5">
              <TMessage content="Official quota" bold />
              <TSwitch
                :checked="invoice_input.property_white"
                @change="
                  invoice_input.property_white = !invoice_input.property_white
                "
                class="mt-2"
                size="lg"
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <TButton
            content="Create invoice"
            icon="cilPlus"
            class="float-right"
            @click="createInvoice"
            :disabled="invoices_creating"
          />
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import InvoiceDetail from "../../components/InvoiceDetail.vue";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
import filterable from "../../mixins/filterable";
import FilterForInvoices from "../../components/FilterForInvoices.vue";

export default {
  mixins: [filterable],
  components: {
    InvoiceDetail,
    FilterForInvoices,
  },
  data() {
    return {
      invoice_input: {
        shipping_date: Date.now(),
        arrival_date: Date.now(),
        booking_date: null,
        property_white: true,
      },
      showModalSelectLadingBill: false,
      shipment_method_key: "smt",
      tabs: [
        {
          key: "list",
          name: "List of bills of lading",
          icon: "cisListRich",
        },
        {
          key: "detail",
          name: "Invoice detail",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.invoices.fetch.if-first-time");
    } else this.$store.dispatch("warehouse.invoices.apply-query", this.query);
  },
  watch: {
    invoice_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.invoices.detail.fetch", id);
          this.fetchLadingBills();
        }
      },
    },
  },
  computed: {
    invoice_id_selected() {
      return this.$route.query.invoice_id;
    },
    invoice_selected() {
      return this.$store.getters["warehouse.invoices.detail"];
    },
    invoices() {
      return this.$store.getters["warehouse.invoices.list"];
    },
    lading_bills() {
      return this.$store.getters["warehouse.lading_bills.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.invoices.fetching"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.lading_bills.loading"];
    },
    invoices_creating() {
      return this.$store.getters["warehouse.invoices.creating"];
    },
  },
  methods: {
    changeTab(tab) {
      let query = this.lodash.omit({ ...this.$route.query, tab: tab }, [
        "action",
      ]);
      this.$router.push({ query: query });
    },
    createInvoice() {
      this.$store
        .dispatch("warehouse.invoices.create", {
          ...this.lodash.pickBy({
            ...this.invoice_input,
            max_volume: this.invoice_input.max_volume * 1000000,
          }),
          from_area_id: warehouseScope.get(),
          property_white: this.invoice_input.property_white ? 1 : 0,
        })
        .then((invoice) => {
          this.invoice_input = {
            shipping_date: Date.now(),
            arrival_date: Date.now(),
            booking_date: null,
            property_white: true,
          };
          this.shipment_method_key = this.lodash.resetKey("smk");
          this.$router.push({ query: { invoice_id: invoice.id } });
        });
    },
    fetchLadingBills() {
      this.$store.dispatch("warehouse.lading_bills.apply-query", {
        "filter[container_id]": this.invoice_id_selected,
        include: "pivotBoxes,boxes.tags,customer,pivotBoxes.order",
      });
    },
    refetchInvoice() {
      this.$store.dispatch("warehouse.invoices.detail.refresh");
    },
    addLadingBill(lading_bill_id) {
      this.$store
        .dispatch("warehouse.invoices.detail.attach-lading-bills", {
          "lading_bill_ids[0]": lading_bill_id,
        })
        .then(() => {
          this.fetchLadingBills();
          this.refetchInvoice();
        });
    },
    removeLadingBill(lading_bill) {
      this.$store
        .dispatch("warehouse.invoices.detail.detach-lading-bills", {
          lading_bill_ids: [lading_bill.id],
        })
        .then(() => {
          this.fetchLadingBills();
          this.refetchInvoice();
        });
    },
    selectContainerType(type) {
      this.invoice_input.max_volume = type.max_volume / 1000000;
      this.invoice_input.max_weight = type.max_weight;
    },
  },
};
</script>
